@import "syntax_highlighting.scss";

$text-color: #222;
$light-text-color: #666;
$font-size-ratio: 1.2;
$small-font-size: calc(1.0em / $font-size-ratio);

html {
  // always display scroll bar so that page centering doesn't shift depending
  // on whether or not the page is long
  overflow-y: scroll;
}

body {
  color: $text-color;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  font-size: 18px;
  width: 32em;
  max-width: 90%;
  margin: 2em auto;
  line-height: 1.5em;
}

p {
  margin-top: 0;
  margin-bottom: 0.75em;
}

h1, h2, h3, h4, h5, h6 {
  color: $text-color;
  line-height: 1.1em;
}

h1 {
  font-size: 1em * $font-size-ratio * $font-size-ratio * $font-size-ratio;
}

h2 {
  font-size: 1em * $font-size-ratio * $font-size-ratio;
}

h3 {
  font-size: 1em * $font-size-ratio;
}

h4, h5, h6 {
  font-size: 1em;
}

a:link, a:visited {
  text-decoration: none;
  color: #028090;
}

a:hover, a:active {
  text-decoration: underline;
  color: #114B5F;
}

a:link, a:visited {
  text-decoration: none;
  color: #028090;
}

a:hover, a:active {
  text-decoration: underline;
  color: #114B5F;
}

ul {
  margin-top: 0;
}

table {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: left;
  border-top: 1px solid $text-color;
  border-bottom: 1px solid $text-color;
  border-collapse: collapse;

  th {
    border-bottom: 1px solid #ddd;
    padding:0.15em 2em;
  }
  td {
    padding:0.15em 2em;
  }
}

.blog-post-title {
  margin-bottom: 0;
}

.blog-post-info {
  font-size: $small-font-size;
  color: $light-text-color;
}

.center {
  text-align: center;
}

// for centering figures that exceed the text width
.center-fig {
  display: flex;
  justify-content: center;
}

.footnotes {
  font-size: $small-font-size;
}

.no-bullet {
  list-style: none;
  padding-left: 0;
}

#blog-post-list {
  ul {
    @extend .no-bullet;
  }

  p {
    margin: 0em;
    font-size: $small-font-size;
  }

  li {
    margin-bottom: 1.5em;
  }
}

#header {
  margin-top: 2em;
  margin-bottom: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
}

#header {
  a:link, a:visited, a:hover, a:active {
    color: $text-color;
  }

  nav {
    float: right;
    font-size: $small-font-size;
  }
}

#header-home {
  float: left;
  font-size: 1em * $font-size-ratio;
}

nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
    padding: 0em .75em;
  }
}

#headshot {
  float: right;
  margin: 0 0 .75em .75em;
  width: 30%;
  max-width: 175px;
  height: auto;
  border-radius: 50%; // crop image to a circle
}

h1 .heading-link, h2 .heading-link, h3 .heading-link, h4 .heading-link, h5 .heading-link, h6 .heading-link  {
  visibility: hidden;
}
h1:hover .heading-link, h2:hover .heading-link, h3:hover .heading-link, h4:hover .heading-link, h5:hover .heading-link, h6:hover .heading-link {
  visibility: visible;
}
